import React, { useState, useMemo } from 'react'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import * as styles from './index.module.less'
import classnames from 'classnames'

// img
import DateIcon from 'assets/images/topic/ds2023Review/ds2023_date.png'
import DateIconMb from 'assets/images/topic/ds2023Review/ds2023_date_mb.png'
const Content = ({ content }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0) // 上午 下午
  const [activeNumber, setActiveNumber] = useState<number>(0) // 左侧 主题内容
  const [activeContentNumber, setActiveContentNumber] = useState<number>(0) // 右侧展开内容
  const isMb = useIsWindowWidthSmaller()

  const activeDataSource = useMemo(() => {
    if (isMb) {
      const newActiveDataSource = content.data.map((item) => item.list)
      return { list: [...newActiveDataSource[0], ...newActiveDataSource[1]] }
    }
    return content?.data[activeIndex]
  }, [activeIndex, isMb])

  const activeContent = useMemo(() => {
    const newActiveContent = activeDataSource?.list[activeNumber]?.item?.dataSource.map((item, index) => {
      return { ...item, flag: activeContentNumber === index }
    })
    return newActiveContent
  }, [activeNumber, activeContentNumber, activeIndex])
  // 改变时间段
  const handleClickDate = (index) => {
    return () => {
      setActiveIndex(index)
      setActiveNumber(0)
      setActiveContentNumber(0)
    }
  }
  // 右侧内容鼠标移入
  const handleMouseEnter = (index) => {
    return () => {
      if (isMb) return
      setActiveContentNumber(index)
    }
  }

  return (
    <div className={styles.container}>
      <div className=" lg:w-[1200px] mx-auto">
        <div className="lg:flex">
          <div className=" left-card  text-[24px]  text-[rgba(255,255,255,0.5)] lg:w-[270px] lg:mr-[56px] ]">
            <div className="lg:w-full w-[244px] px-[20px] mt-[75px] lg:mt-0">
              <img src={isMb ? DateIconMb : DateIcon} alt="" />
            </div>
            <div className="date-card mt-[44px] w-full lg:flex hidden">
              {content?.data?.map((item, index) => {
                return (
                  <div
                    key={item?.name}
                    className={classnames('date-card-item flex-1 leading-[54px] text-center', {
                      'active-card': activeIndex === index,
                    })}
                    onClick={handleClickDate(index)}
                  >
                    {item?.name}
                  </div>
                )
              })}
            </div>
            <div className="w-full overflow-hidden">
              <div className="w-full overflow-x-scroll pl-[20px] lg:pl-0 mt-[12px] flex  flex-nowrap whitespace-nowrap lg:block lg:mt-[44px] list-message">
                {activeDataSource?.list?.map((item, index) => {
                  const customStyles: any = {
                    '--image': `url(${item.item.mb_bg})`,
                  }
                  return (
                    <div
                      style={customStyles}
                      key={item?.item?.value}
                      className={classnames('list-message-item lg:mb-[24px] mb-[10px]', {
                        'active-list-item': activeNumber === index,
                      })}
                      onClick={() => {
                        setActiveContentNumber(0)
                        setActiveNumber(index)
                      }}
                    >
                      {item?.item?.value}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="right-card lg:flex-1 text-[#fff]">
            {activeContent?.map((item, index) => {
              return (
                <div
                  onMouseEnter={handleMouseEnter(index)}
                  className="right-card-wrapper mt-[10px]  mx-[20px] lg:mx-0"
                  key={item?.person_name}
                >
                  <div
                    className={classnames('card-content  lg:my-0 lg:px-0 pb-[20px] lg:pb-0', {
                      'card-content-active ': item?.flag,
                    })}
                  >
                    <div className="lg:flex items-center">
                      <div className="right-card-img lg:pr-[40px] px-[8px] py-[8px] lg:px-0 lg:py-0">
                        <img
                          className=" lg:w-[228px]  w-full lg:h-[128px]"
                          src={item?.theme_photo?.publicURL}
                          alt={item?.theme}
                        />
                      </div>
                      <div className="lg:flex-1 mt-[8px] lg:mt-0 px-[14px] lg:px-0">
                        <div className="card-content-title  text-[18px] lg:text-[24px] lg:leading-[34px] font-medium whitespace-pre-wrap">
                          {item?.theme}
                        </div>
                        <div className="text-[14px] leading-[14px] lg:text-[12px] mt-[4px] lg:leading-[24px] ">
                          {item?.person_name}
                        </div>
                      </div>
                    </div>
                    <div className=" text-justify lg:text-left  card-content-main text-[14px] px-[14px] lg:px-0  mt-[24px] leading-[24px]">
                      {item?.theme_content}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Content
