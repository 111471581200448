import React from 'react'
import * as styles from './index.module.less'

interface BannerProps {
  /**标题 */
  title?: string
  /**副标题/描述 */
  subTitle?: string
  /**跳转链接 */
  link?: string
}

const Banner: React.FC<BannerProps> = (props) => {
  const { title = '开幕式', subTitle = '新旅程 新经营 决胜数字化', link = '' } = props

  const handleClick = () => {
    const a = document.createElement('a')
    a.href = link
    a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  return (
    <div className={styles.topicReviewBannerWrapper}>
      <div className={styles.content}>
        <div className={styles.videoWrapper} onClick={handleClick}>
          <div className={styles.playIcon}></div>
        </div>
        <div className={styles.textWrapper}>
          <div>
            <div className={styles.title}>{title}</div>
            <div className={styles.subTitle}>{subTitle}</div>
          </div>
          <div className={styles.btn} onClick={handleClick}>
            <div className={styles.text}>观看回放</div> <div className={styles.to}></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
