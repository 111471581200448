// extracted by mini-css-extract-plugin
export var btn = "index-module--btn--Kzsfs";
export var content = "index-module--content--IEgNa";
export var playIcon = "index-module--playIcon--Cb9GZ";
export var subTitle = "index-module--subTitle--s3Grk";
export var text = "index-module--text--hpGsn";
export var textWrapper = "index-module--textWrapper--Wrmci";
export var title = "index-module--title--dMP7h";
export var to = "index-module--to--4frGW";
export var topicReviewBannerWrapper = "index-module--topicReviewBannerWrapper--clXh-";
export var videoWrapper = "index-module--videoWrapper--gDfZp";